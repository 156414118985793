"use strict";
let Vimeo = require('vimeo').Vimeo;
let client = new Vimeo("a3855d85f906a12d4463bb081504d2d7220d522d", "nNWxiv71ZF19oQKaoqa3nKMqBAYpovWtgJaFFpTA5le52+FaKdj5YjOjkDV2Ft6WIw9ZOn1GkfAPKMJBiAj0ItRBpOHLY976a8B3au/J8iFPpRaH/FtkX1uYQ7pwzDlx", "a558105e00804f131790eedf14910c8f");
//console.log('foo bar 2');
let video_ids = document.currentScript.getAttribute('data-video-ids').split(',');
video_ids.forEach(function (video) {
    //console.log(video, 'video');
    client.request({
        method: 'GET',
        path: '/videos/' + video + '/pictures'
    }, function (error, body, status_code, headers) {
        if (error) {
            console.log(error);
        }
        let images = body.data;
        images.forEach(function (image) {
            if (image.active == true) {
                //console.log(video)
                //console.log('image',image)
                var el = document.getElementById(video);
                //console.log(el.hasAttribute('src'),'has src 2');
                if (el.hasAttribute('src')) {
                    //do src stuff for old elements
                    var imageEl = el;
                    imageEl.src = image.base_link;
                    imageEl.style.opacity = '1';
                }
                else {
                    //do lite stuff
                    el.style.opacity = '1';
                    el.style.backgroundImage = 'url(' + image.base_link + '.webp?mw=1600&mh=900&q=70)';
                }
                //as HTMLImageElement
                //console.log(el,'imageEl 2')
            }
        });
        //console.log(body);
    });
});
//   client.request({
//     method: 'GET',
//     path: '/tutorial'
//   }, function (error:any, body:any, status_code:any, headers:any) {
//     if (error) {
//       console.log(error);
//     }
//     console.log(body);
//   })
//   function vimeo_thumbnail(id:any){
//     console.log('thumbnail');
//   }
